.condom-sidebar-right {
  width: 300px;
  position: fixed;
  top: 0;
  right: -100vw;
  height: 100vh;
  z-index: 999;
  background: white;
  transition: all 0.3s;
   box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);

 
  @media (min-width:768px) {
    width: 600px;
  }
  .container{
    overflow-y: scroll;
    height: calc(100% - 113px);
  }
}

.condom-sidebar-right.active {
  right: 0px;

  @media (min-width:768px) {
    width: 600px;
  }

}

.condom-sidebar-right .dismiss {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.condom-sidebar-right .dismiss:hover {
  color: #7386D5;
}
 
.condom-sidebar-right .sidebar-header {
  padding: 20px;
  background: #2f2851;
  color: #FFF;
  text-align: center;
}
